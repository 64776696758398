<template>
  <a-modal
    :width="1000"
    :title="title"
    :visible="innerVisible"
    @cancel="handleCancel"
    cancelText="关闭"
    :okButtonProps="{ style: { display: 'none' } }"
  >
    <a-alert type="info" showIcon style="margin-bottom: 16px">
      <template slot="message">
        <span>已选择</span>
        <a style="font-weight: 600; padding: 0 4px">{{ selectedRowKeys.length }}</a>
        <span>项</span>
        <template v-if="selectedRowKeys.length > 0">
          <a-divider type="vertical" />
          <a @click="handleClearSelection">清空选择</a>
          <a-divider type="vertical" />
          <a @click="handleRevertBatch" v-has="'platform:recyclebin:button:restore'">批量还原</a>
          <a-divider type="vertical" />
          <a @click="handleDeleteBatch" v-has="'platform:recyclebin:button:delete'">批量删除</a>
        </template>
      </template>
    </a-alert>

    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :loading="loading"
      :dataSource="dataSource"
      :pagination="false"
      :rowSelection="{ selectedRowKeys, onChange: handleTableSelectChange }"
    >
      <template slot="adminUsers" slot-scope="text, record">
        <div class="flex flex-wrap">
          <span color="blue" v-for="item in record.adminUsers" :key="item.userId">{{ item.userName }}</span>
        </div>
      </template>

      <template slot="department" slot-scope="text, record">
        {{ record.department ? record.department.departName : '无' }}
      </template>

      <span slot="action" slot-scope="text, record">
        <a @click="handleRevert([record.id])" v-has="'platform:recyclebin:button:restore'"> 恢复平台</a>
        <a-divider type="vertical" />
        <a @click="handleDelete([record.id])" v-has="'platform:recyclebin:button:delete'"> 彻底删除</a>
      </span>
    </a-table>
  </a-modal>
</template>

<script>
import { putAction, deleteAction, getFileAccessHttpUrl } from '@/api/manage'

// 高度封装的请求，请务必使用 superRequest.call(this,{}) 的方式调用
function superRequest(options) {
  this.loading = !!options.loading
  options.promise
    .then((res) => {
      if (res.success && typeof options.success === 'function') {
        options.success(res)
      } else {
        throw new Error(res.message)
      }
    })
    .catch((e) => {
      console.error('查询已删除的平台失败：', e)
      this.$message.warning('查询已删除的平台失败：' + (e.message || e))
    })
    .finally(() => {
      this.loading = false
    })
}

export default {
  name: 'PlatformRecycleBinModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: '平台回收站',
      loading: false,
      innerVisible: false,
      selectedRowKeys: [],
      dataSource: [],
      columns: [
        { title: '序号', dataIndex: 'sequenceNo' },
        { title: '平台名称', dataIndex: 'platformName' },
        { title: '统筹', dataIndex: 'adminUsers', scopedSlots: { customRender: 'adminUsers' } },
        { title: '所属部门', dataIndex: 'department', scopedSlots: { customRender: 'department' } },
        { title: '操作', dataIndex: 'action', width: 200, scopedSlots: { customRender: 'action' } },
      ],
      url: {
        // 回收站操作，get = 获取列表；put = 取回；delete = 彻底删除
        recycleBin: '/platform/recycleBin',
        putRecycleBin: '/platform/putRecycleBin',
        deleteRecycleBin: '/platform/deleteRecycleBin',
      },
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadData()
        }
        this.innerVisible = val
      },
    },
    innerVisible(val) {
      this.$emit('update:visible', val)
    },
  },
  methods: {
    loadData() {
      superRequest.call(this, {
        loading: true,
        promise: this.$http.get(this.url.recycleBin),
        success: (res) => (this.dataSource = res.data),
      })
    },
    handleOk() {
      this.loadData()
      this.$emit('ok')
    },
    handleCancel() {
      this.innerVisible = false
    },
    // 还原平台
    handleRevert(platformIds) {
      this.$confirm({
        title: '恢复平台',
        content: `您确定要恢复这 ${platformIds.length} 个平台吗？`,
        centered: true,
        onOk: () => {
          putAction(this.url.putRecycleBin, { platformIds: platformIds.join(',') }).then((res) => {
            if (res.success) {
              this.handleOk()
              this.handleClearSelection()
              this.$message.success(`还原 ${platformIds.length} 个平台成功！`)
            }
          })
        },
      })
    },
    // 彻底删除平台
    handleDelete(platformIds) {
      this.$confirm({
        title: '彻底删除平台',
        content: (
          <div>
            <p>您确定要彻底删除这 {platformIds.length} 个平台吗？</p>
            <p style="color:red;">注意：彻底删除后将无法恢复，请谨慎操作！</p>
          </div>
        ),
        centered: true,
        onOk: () => {
          var that = this
          deleteAction(that.url.deleteRecycleBin, { platformIds: platformIds.join(',') }).then((res) => {
            if (res.success) {
              this.loadData()
              this.handleClearSelection()
              this.$message.success(`彻底删除 ${platformIds.length} 个平台成功！`)
            } else {
              that.$message.warning(res.message)
            }
          })
        },
      })
    },
    handleRevertBatch() {
      this.handleRevert(this.selectedRowKeys)
    },
    handleDeleteBatch() {
      this.handleDelete(this.selectedRowKeys)
    },
    handleClearSelection() {
      this.handleTableSelectChange([], [])
    },
    handleTableSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectionRows = selectionRows
    },
  },
}
</script>

<style lang="less" scoped></style>